import React from "react";

const Form = () => {
  return (
    <>
      <form
        action="https://forms.zohopublic.com.au/symplist251/form/ContactForm/formperma/zRM-CTijLXRa7kQ88HlQqWEZGI1wKUX70DigsbRpvv0/htmlRecords/submit"
        name="form"
        method="POST"
        accept-charset="UTF-8"
        enctype="multipart/form-data"
      >
        <div class="max-w-4xl mx-auto sm:px-6 lg:px-8 mb-32">
          <div class="overflow-hidden rounded shadow-2xl">
            <div class="px-6 py-4 bg-white border-b border-gray-200">
              <h2 class="text-3xl font-bold text-center m-4 uppercase">
                Contact Symplist
              </h2>
              <p class="">We'd love to hear from you.</p>
            </div>

            <div class="p-6 bg-white border-b border-gray-200">
              <div class="grid grid-cols-1 gap-6">
                <label class="block text-left mb-2">
                  <span class="text-left font-semibold text-secondary">
                    Your full name <em class="text-danger">*</em>
                  </span>
                  <div class="flex-col">
                    <input
                      required
                      type="text"
                      name="Name_First"
                      maxlength="255"
                      class="py-4 mt-2 flex-auto rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring focus:ring-secondary focus:ring-opacity-50 inline-flex"
                      placeholder="First name"
                    />
                    <input
                      required
                      type="text"
                      name="Name_Last"
                      maxlength="255"
                      class="py-4 mt-2 flex-auto rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring focus:ring-secondary focus:ring-opacity-50 inline-flex"
                      placeholder="Last name"
                    />
                  </div>
                </label>
                <label class="block text-left mb-2">
                  <span class="text-left font-semibold text-secondary">
                    Email address <em class="text-danger">*</em>
                  </span>
                  <input
                    required
                    name="Email"
                    type="email"
                    class="py-4 mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring focus:ring-secondary focus:ring-opacity-50"
                    placeholder="john@example.com"
                  />
                </label>

                <label class="block text-left mb-2">
                  <span class="text-left font-semibold text-secondary">
                    Company <em class="text-danger">*</em>
                  </span>
                  <input
                    required
                    name="SingleLine"
                    type="text"
                    maxlength="255"
                    class="py-4 mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring focus:ring-secondary focus:ring-opacity-50"
                    placeholder="Some Company Pty Ltd"
                  />
                </label>

                <label class="block text-left mb-2">
                  <span class="text-left font-semibold text-secondary mb-2">
                    How many researchers on your team?{" "}
                    <em class="text-danger">*</em>
                  </span>
                  <select
                    name="Dropdown"
                    class="py-4 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring focus:ring-secondary focus:ring-opacity-50 mt-2"
                  >
                    <option value="-1">Prefer not to say</option>
                    <option value="1">1</option>
                    <option value="2-10">2-10</option>
                    <option value="11-50" selected>
                      11-50
                    </option>
                    <option value="51-200">51-200</option>
                    <option value="201-500">201-500</option>
                    <option value="500&#x2b;">500&#x2b;</option>
                  </select>
                </label>
                <label class="block text-left mb-2">
                  <span class="text-left font-semibold text-secondary mb-2">
                    Continent
                  </span>
                  <select
                    name="Dropdown1"
                    required
                    class="py-4 block w-full mt-2 rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring focus:ring-secondary focus:ring-opacity-50"
                  >
                    <option value="Africa">Africa</option>
                    <option value="Antarctica">Antarctica</option>
                    <option value="Asia">Asia</option>
                    <option value="Australia" selected>
                      Australia
                    </option>
                    <option value="Europe">Europe</option>
                    <option value="North&#x20;America">North America</option>
                    <option value="South&#x20;America">South America</option>
                  </select>
                </label>

                <label class="block text-left mb-2">
                  <span class="text-left font-semibold text-secondary mb-2">
                    How can we help you?
                  </span>
                  <textarea
                    name="MultiLine"
                    maxlength="65535"
                    class="py-4 mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring focus:ring-secondary focus:ring-opacity-50"
                    rows="5"
                  ></textarea>
                </label>
              </div>
            </div>

            <div class="p-6 bg-white border-gray-200 text-right">
              <button
                type="submit"
                class="md:w-32 bg-primary hover:bg-blue-dark text-white font-bold py-3 px-6 rounded-lg mt-3 hover:bg-secondary transition ease-in-out duration-300"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
        <input type="hidden" name="zf_referrer_name" value="" />
        <input type="hidden" name="zf_redirect_url" value="" />
        <input type="hidden" name="zc_gad" value="" />
        <input type="hidden" name="utm_source" value="comingsoon" />
        <input type="hidden" name="utm_medium" value="" />
        <input type="hidden" name="utm_campaign" value="" />
        <input type="hidden" name="utm_term" value="" />
        <input type="hidden" name="utm_content" value="" />
      </form>
      <div class="space-x-32"></div>
    </>
  );
};

export default Form;
