import logo from "./logo.svg";
import "./App.css";
import Form from "./Form";
import Footer from "./Footer";

function App() {
  return (
    <>
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo mb-5" alt="logo" />
          <p>The platform to simplify your research.</p>
        </header>
        <Form />
      </div>
      <Footer />
    </>
  );
}

export default App;
